import { render, staticRenderFns } from "./DirectoryListWrapper.vue?vue&type=template&id=5e1e46c2&lang=html"
import script from "./DirectoryListWrapper.vue?vue&type=script&lang=js"
export * from "./DirectoryListWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../modules/tukes/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports