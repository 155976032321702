import { render, staticRenderFns } from "./FeedbackWrapper.vue?vue&type=template&id=1b17fb3c&scoped=true&lang=html"
import script from "./FeedbackWrapper.vue?vue&type=script&lang=javascript"
export * from "./FeedbackWrapper.vue?vue&type=script&lang=javascript"


/* normalize component */
import normalizer from "!../../modules/tukes/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b17fb3c",
  null
  
)

export default component.exports